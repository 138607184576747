(function () {
  'use strict';

  function welcome() {
    console.log("Welcome to my website!"); // eslint-disable-line no-console
  }

  function env() {
    const bodyClasses = [...document.body.classList];
    return bodyClasses.includes("dev") ? "dev" : "prod";
  }

  function css() {
    const elts = [...document.getElementsByTagName("link")];
    const link = elts.find((elt) => {
      const { rel, href } = elt;
      return rel === "stylesheet" && href.match(/\/main-.+\.min\.css$/);
    });

    return link && link.href;
    // return styleLink?.href.match(/-(.+)\.min\.css/)[0];
  }

  function js() {
    const elts = [...document.getElementsByTagName("script")];
    const script = elts.find((elt) => elt.src.match(/\/main-.+\.min\.js$/));

    return script && script.src;
  }

  function id(href) {
    const matches = href.match(/main-(.+)\.min\.[a-z]+$/);
    return matches && matches.length === 2 && matches[1];
  }

  welcome();

  window.addEventListener("load", () => {
    const targetEnv = "dev";

    if (env() === targetEnv) {
      const hrefCss = css();
      const hrefJs = js();
      const idCss = id(hrefCss);
      const idJs = id(hrefJs);
      const pCss = document.createElement("p");
      const pJs = document.createElement("p");

      pCss.innerHTML = `CSS: ${idCss}`;
      pJs.innerHTML = `JS: ${idJs}`;
      document.body.appendChild(pCss);
      document.body.appendChild(pJs);
    }
  });

}());
